
import { defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiMenu } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "add-menu-modal",
  components: {},
  emits: ["update-list"],
  props: {
    selectData: {
      type: Array,
      required: true,
    },
    cascaderData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);
    const addMenuModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "children",
      lazy: true,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });

    const formData = ref({
      status: 10,
      name: "",
      app_id: "",
      parent_id: "",
      route_uri: "",
      icon_font_name: "",
      icon_svg_path: "",
      sorting: 0,
      overall_notes: "",
    });

    const appTypes = ref([]);

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.status") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("permissions.menuName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      parent_id: [
        {
          required: false,
          message: t("permissions.parentID") + t("common.isRequired"),
          trigger: "blur",
        },
      ],
    });

    watch(
      formData,
      (nV, oV) => {
        if (nV?.parent_id !== "" && nV?.parent_id !== "0") {
          nV.app_id = "";
        }
      },
      { deep: true }
    );

    const parentChange = (value) => {
      console.log("parentChange", value);
      if (value) {
        formData.value.parent_id = value[value.length - 1];
      } else {
        formData.value.parent_id = "";
      }
      console.log("parentChange", value);
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        console.log("formData.value", formData.value);
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiMenu.addMenu(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addMenuModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addMenuModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    return {
      t,
      loading,
      propData,
      formData,
      rules,
      formRef,
      addMenuModalRef,
      submitButton,
      appTypes,
      parentChange,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
