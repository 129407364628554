
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  status: number;
  name: string;
  app_id: string;
  parent_id: string;
}

interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "filter-menu-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  props: {
    selectData: {
      type: Array,
      required: true,
    },
    cascaderData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);

    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "children",
      lazy: true,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });

    const formData = ref<Filter>({
      status: 10,
      name: "",
      app_id: "",
      parent_id: "",
    });

    const parentChange = (value) => {
      if (value) {
        formData.value.parent_id = value[value.length - 1];
      } else {
        formData.value.parent_id = "0";
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      propData,
      formData,
      formRef,
      parentChange,
      submit,
      handleReset,
    };
  },
});
