import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_app"
}
const _hoisted_12 = { class: "svg-icon svg-icon-2" }
const _hoisted_13 = { class: "card-body pt-0" }
const _hoisted_14 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_AddModal = _resolveComponent("AddModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
            ]),
            _createVNode(_component_FilterDropdown, {
              selectData: _ctx.selectData,
              cascaderData: _ctx.cascaderData,
              onSubmitFilter: _ctx.handleFilter,
              onResetFilter: _ctx.handleFilterReset
            }, null, 8, ["selectData", "cascaderData", "onSubmitFilter", "onResetFilter"]),
            _createElementVNode("button", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.add") + _ctx.t("permissions.menu")), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_el_table, {
          data: _ctx.tableData,
          "row-key": "id",
          "tree-props": {
          children: 'children',
          hasChildren: 'has_children',
        }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
              return (_openBlock(), _createBlock(_component_el_table_column, {
                key: i,
                label: cell.name,
                align: cell.key == 'actions' ? 'right' : 'left',
                width: cell.key == 'actions' ? '100px' : '',
                "min-width": cell.key == 'name' ? '200px' : ''
              }, _createSlots({ _: 2 }, [
                (cell.key == 'actions')
                  ? {
                      name: "default",
                      fn: _withCtx((scope) => [
                        _createVNode(_component_router_link, {
                          to: '/permissions/menu/' + scope.row.id,
                          class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_14, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                            ])
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ])
                    }
                  : (cell.key == 'status')
                    ? {
                        name: "default",
                        fn: _withCtx((scope) => [
                          (scope.row[cell.key] == 10)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "badge badge-light-success"
                              }, _toDisplayString(_ctx.t("permissions.activated")), 1))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: "badge badge-light-danger"
                              }, _toDisplayString(_ctx.t("permissions.deactivated")), 1))
                        ])
                      }
                    : {
                        name: "default",
                        fn: _withCtx((scope) => [
                          _createTextVNode(_toDisplayString(scope.row[cell.key]), 1)
                        ])
                      }
              ]), 1032, ["label", "align", "width", "min-width"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["data"])
      ])
    ]),
    _createVNode(_component_AddModal, {
      onUpdateList: _ctx.updateList,
      selectData: _ctx.selectData,
      cascaderData: _ctx.cascaderData
    }, null, 8, ["onUpdateList", "selectData", "cascaderData"])
  ], 64))
}