
import { defineComponent, ref, onMounted } from "vue";
import AddModal from "@/views/menu/AddModal.vue";
import FilterDropdown from "@/views/menu/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ApiMenu } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

interface ApiData {
  id: number;
  status: number;
  name: string;
  app_id: string;
  parent_id: string;
  route_uri: string;
  icon_font_name: string;
  icon_svg_path: string;
  sorting: number;
  overall_notes: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface Menu {
  id: string;
  name: string;
  parent_id: string;
  children: Array<any>;
}

export default defineComponent({
  name: "menu-index",
  components: {
    AddModal,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const tableData = ref<Array<ApiData>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([]);
    const { showServerErrorMsg } = mixin();
    const selectData = ref([]);
    const cascaderData = ref<Array<Menu>>([]);

    const tableHeader = ref([
      {
        name: t("permissions.name"),
        key: "name",
        sortable: true,
      },
      {
        name: t("permissions.routeUrl"),
        key: "route_uri",
        sortable: false,
      },
      {
        name: t("permissions.sorting"),
        key: "sorting",
        sortable: false,
      },
      {
        name: t("common.status"),
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("permissions.menuManagement"), [
        { name: t("permissions.permissions"), path: "" },
      ]);
      getAppTypeOptions();
      getSelectList();
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    });

    const getTableList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiMenu.getMenuList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAppTypeOptions = (status?: number) => {
      ApiMenu.getAppSourceData({ status })
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value = data.data;
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getSelectList = (id?: string) => {
      ApiMenu.getMenuSourceData({ id })
        .then(({ data }) => {
          if (data.code == 0) {
            cascaderData.value.splice(
              0,
              cascaderData.value.length,
              ...data.data
            );
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getTableList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getTableList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      const DataEnums = {
        status: { field: "status", condition: "eq" },
        name: { field: "name", condition: "like" },
        app_id: { field: "app_id", condition: "eq" },
        parent_id: { field: "parent_id", condition: "eq" },
      };
      for (let item in val) {
        if (val[item] != "") {
          filterArr.push({
            field: DataEnums[item].field,
            value: val[item],
            condition: DataEnums[item].condition,
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getTableList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getTableList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      selectData,
      cascaderData,
      getTableList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
    };
  },
});
